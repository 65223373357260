@import "../../screen.scss";

// Overrides of "react-tostify"
:root {
  --toastify-color-dark: var(--color-dark-500) !important;
  --toastify-color-progress-dark: var(--color-secondary-500) !important;
  --toastify-text-color-dark: var(--color-text-200) !important;

  --toastify-color-progress-light: var(--color-secondary-500) !important;
  --toastify-text-color-light: var(--color-text-900) !important;
}

@media only screen and (max-width: $screen-mobile) {
  .Toastify__toast-container {
    width: 100vw !important;
    left: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
  }
}
